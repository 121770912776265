import React, { FC, useEffect, useState } from 'react'
import clsx from "clsx";

import verified from '../../../assets/icons/verified.svg';
import Sparkline from './Sparkline';
interface Props {
    className?: string
    coinId: string
}
const options = {
    headers: {
        'Content-Type': 'application/json',
        'x-access-token': 'coinrankingc4a87e62f8b1cd0bb038c6d9153a457cf5db140b46dd1551',
    },
};
const GainerList: FC<Props> = ({ className, coinId }) => {
    const [coinDetail, setCoindetail] = useState(undefined);
    
    const [coinDetail1, setCoindetail1] = useState(undefined);
    const [coinDetail2, setCoindetail2] = useState(undefined);
    const [coinDetail3, setCoindetail3] = useState(undefined);
    


    const fetchData = async () => {
        try {
            {/* const response = await fetch(`https://api.coinranking.com/v2/coin/${coinId}`, options); */}
            const response = await fetch(`https://api.coinranking.com/v2/coin/HIVsRcGKkPFtW`, options);
            const result = await response.json();
            
            setCoindetail(result?.data?.coin || undefined);
        } catch (error) {
            console.error('Error fetching price data:', error);
        }
    }


    const fetchData1 = async () => {
        try {
            {/* const response = await fetch(`https://api.coinranking.com/v2/coin/${coinId}`, options); */}
            const response = await fetch(`https://api.coinranking.com/v2/coin/HIVsRcGKkPFtW`, options);
            const result = await response.json();
            
            setCoindetail1(result?.data?.coin || undefined);
        } catch (error) {
            console.error('Error fetching price data:', error);
        }
    }
    
        const fetchData2 = async () => {
        try {
            {/* const response = await fetch(`https://api.coinranking.com/v2/coin/${coinId}`, options); */}
            const response = await fetch(`https://api.coinranking.com/v2/coin/aKzUVe4Hh_CON`, options);
            const result = await response.json();
            
            setCoindetail2(result?.data?.coin || undefined);
        } catch (error) {
            console.error('Error fetching price data:', error);
        }
    }
    
    const fetchData3 = async () => {
        try {
            {/* const response = await fetch(`https://api.coinranking.com/v2/coin/${coinId}`, options); */}
            const response = await fetch(`https://api.coinranking.com/v2/coin/MoTuySvg7`, options);
            const result = await response.json();
            
            setCoindetail3(result?.data?.coin || undefined);
        } catch (error) {
            console.error('Error fetching price data:', error);
        }
    }
    
    
    useEffect(() => {
        fetchData();
        fetchData1();
        fetchData2();
        fetchData3();
    }, [])
    
    const OpenAIImg = require('./OpenAI.png')
    const GeminiImg = require('./Gemini.png')
    const AnthropicImg = require ('./Anthropic.png')
    
    return (
        <div style={{ transition: "background-color .1s ease-in-out" }}
            className={clsx(`grid grid-cols-[300px_1fr_1fr_1fr_1fr_100px] max-lg:grid-cols-[1fr_1fr] gap-2 w-full p-[16px_24px] items-center bg-white rounded-xl shadow-[0_1px_2px_0_rgba(0,0,0,0.11)] cursor-pointer hover:bg-[#0017160a] dark:bg-[#ffffff0a] dark:hover:bg-[#ffffff14]`, className)}>
            <div className="flex gap-6">
                <div className="h-[55.05px]">
                    {coinDetail && <img className="w-[48px] h-[48px] rounded-full"
                        src={OpenAIImg}
                        alt="image" />}
                </div>
                <div>
                    <div className="flex items-center gap-1">
                        {coinDetail && <p className="text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] dark:text-white">{/* {coinDetail.name}*/}OpenAI</p>}
                        <img className="w-4 h-4" src={verified} alt="icon" />
                    </div>
                    {coinDetail && <div
                        className="flex items-center gap-1 text-[#a7a7a7] text-[13px] font-medium leading-[1.85em] -tracking-[0.04px]">
                        {/* {coinDetail.symbol} */}GPT4.0
                    </div>}
                </div>
            </div>

            <div className="text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] max-lg:text-right max-lg:justify-self-end">
                <span className="max-lg:block hidden text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#7e7e7e]">Efficiency</span>
                {coinDetail && <p className="dark:text-white">0.97</p>}
            </div>

            <div>
                <span
                    className="max-lg:block hidden text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#7e7e7e]">Variance</span>
                {coinDetail && <p className={`text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] ${coinDetail.change < 0 ? 'text-[#f65549]' : 'text-[#30967d]'}`}>0.03</p>}
            </div>

            <div className="max-lg:text-right max-lg:justify-self-end">
                <span
                    className="max-lg:block hidden text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#7e7e7e]">Total Calculations</span>
                {coinDetail && <p className="text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] capitalize dark:text-white">4,334,482,482</p>}
            </div>

            <div>
                <span
                    className="max-lg:block hidden text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#7e7e7e]">Daily Calculations</span>
                {coinDetail && <p className="text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#a7a7a7]">
                    20,524,521</p>}
            </div>

            <div className="w-[100px] h-[50px] content-center">
                <Sparkline sparklineData={coinDetail1? coinDetail1.sparkline: []}/>
                {/* <canvas style={{ display: "block", boxSizing: "border-box", height: "50px", width: "100px" }} width="100" height="50"></canvas> */}
                
            </div>
            
            
            
            
            <div className="flex gap-6">
                <div className="h-[55.05px]">
                    {coinDetail && <img className="w-[48px] h-[48px] rounded-full"
                        src={GeminiImg}
                        alt="image" />}
                </div>
                <div>
                    <div className="flex items-center gap-1">
                        {coinDetail && <p className="text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] dark:text-white">Google</p>}
                        <img className="w-4 h-4" src={verified} alt="icon" />
                    </div>
                    {coinDetail && <div
                        className="flex items-center gap-1 text-[#a7a7a7] text-[13px] font-medium leading-[1.85em] -tracking-[0.04px]">
                        Gemini 1.5 Pro
                    </div>}
                </div>
            </div>

            <div className="text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] max-lg:text-right max-lg:justify-self-end">
                <span className="max-lg:block hidden text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#7e7e7e]">Efficiency</span>
                {coinDetail && <p className="dark:text-white">0.96</p>}
            </div>

            <div>
                <span
                    className="max-lg:block hidden text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#7e7e7e]">Variance</span>
                {coinDetail && <p className={`text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] ${coinDetail.change < 0 ? 'text-[#f65549]' : 'text-[#30967d]'}`}>0.02</p>}
            </div>

            <div className="max-lg:text-right max-lg:justify-self-end">
                <span
                    className="max-lg:block hidden text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#7e7e7e]">Total Calculations</span>
                {coinDetail && <p className="text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] capitalize dark:text-white">4,735,252,581</p>}
            </div>

            <div>
                <span
                    className="max-lg:block hidden text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#7e7e7e]">Daily Calculations</span>
                {coinDetail && <p className="text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#a7a7a7]">
                    23,248,241</p>}
            </div>

            <div className="w-[100px] h-[50px] content-center">
                <Sparkline sparklineData={coinDetail2? coinDetail2.sparkline: []}/>
                {/* <canvas style={{ display: "block", boxSizing: "border-box", height: "50px", width: "100px" }} width="100" height="50"></canvas> */}
                
            </div>
            
            
            
            
            <div className="flex gap-6">
                <div className="h-[55.05px]">
                    {coinDetail && <img className="w-[48px] h-[48px] rounded-full"
                        src={AnthropicImg}
                        alt="image" />}
                </div>
                <div>
                    <div className="flex items-center gap-1">
                        {coinDetail && <p className="text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] dark:text-white">Anthropic</p>}
                        <img className="w-4 h-4" src={verified} alt="icon" />
                    </div>
                    {coinDetail && <div
                        className="flex items-center gap-1 text-[#a7a7a7] text-[13px] font-medium leading-[1.85em] -tracking-[0.04px]">
                        Claude 3.5 Sonnet
                    </div>}
                </div>
            </div>

            <div className="text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] max-lg:text-right max-lg:justify-self-end">
                <span className="max-lg:block hidden text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#7e7e7e]">Variance</span>
                {coinDetail && <p className="dark:text-white">0.96</p>}
            </div>

            <div>
                <span
                    className="max-lg:block hidden text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#7e7e7e]">Variance</span>
                {coinDetail && <p className={`text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] ${coinDetail.change < 0 ? 'text-[#f65549]' : 'text-[#30967d]'}`}>0.04</p>}
            </div>

            <div className="max-lg:text-right max-lg:justify-self-end">
                <span
                    className="max-lg:block hidden text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#7e7e7e]">Total Calculations</span>
                {coinDetail && <p className="text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] capitalize dark:text-white">3,872,362,584</p>}
            </div>

            <div>
                <span
                    className="max-lg:block hidden text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#7e7e7e]">Daily Calculations</span>
                {coinDetail && <p className="text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] text-[#a7a7a7]">
                    22,521,267</p>}
            </div>

            <div className="w-[100px] h-[50px] content-center">
                <Sparkline sparklineData={coinDetail3? coinDetail3.sparkline: []}/>
                {/* <canvas style={{ display: "block", boxSizing: "border-box", height: "50px", width: "100px" }} width="100" height="50"></canvas> */}
                
            </div>
            
            
            
            
            
        </div>
        
        
        
    )
}

export default GainerList;
