import React, {useEffect} from 'react';
import './App.scss';
import AppRouter from "./AppRouter";
import {useDispatch} from "react-redux";
import {initApp} from "../../redux/app/actions/app";
import { useSelector } from 'react-redux';
import {RootState} from '../../redux/store';


function App(): JSX.Element {
    const dispatch = useDispatch();

    const isDarkMode = useSelector((state:RootState) => state.theme.dark)
    {/* const isDarkMode = useSelector((state:RootState) => true) */}

    useEffect(() => {
        dispatch(initApp());
    }, []);


    useEffect(()=> {
        if (true) {
            document.body.classList.add('dark')
        }else {
            document.body.classList.remove('dark')
        }
    }, [isDarkMode])

  return (
      <div className="app-root">
          <AppRouter></AppRouter>
      </div>
  );
}

export default App;
